import React, { CSSProperties, useEffect } from 'react'

import { VideoProps } from '../../types/props'
import { Node, useEditor, useNode } from '@craftjs/core'
import { getStyle } from '../../services/HelperService'
import { useMediaQuery } from '@mui/material'
import ReactPlayer from 'react-player'
import { useRecipient } from '../../contexts/RecipientContext'
import './Video.scss'

export const Video = (videoProps: VideoProps) => {
	const { actions: {setProp}, parent } = useNode((node) => ({parent: node.data.parent as string}))
	const { query: {node} } = useEditor()
	const mobile = useMediaQuery('(max-width:500px)')
	const { recipient }: any = useRecipient()
  let parentNode: Node = node(parent).get()
  let style: CSSProperties = {...getStyle(videoProps.style, videoProps.mobileStyle, parentNode, mobile)}

	useEffect(() => {
		if (videoProps.variable) {
			setProp((props: VideoProps) => props.url = recipient[videoProps.variable.name])
		}
	}, [recipient])

	return <div className={`dm-edit-video ${!videoProps.url && !videoProps.variable?.preview ? 'dm-edit-video--empty' : ''}`}
		style={style}>
		{ videoProps.url ?
			<ReactPlayer url={videoProps.url}
				controls={videoProps.controls}
				className="dm-edit__react-player"
				width="100%"
				height="100%"
				playing={videoProps.autoplay}
				loop={videoProps.loop}
				muted={videoProps.muted}/>
			:
			<div style={{textAlign: 'center'}}>Please select a video</div>
		}
	</div>
}

Video.craft = {
  displayName: 'Video'
}
