import React, { CSSProperties, useEffect, useState } from 'react'

import { useEditor, useNode } from '@craftjs/core'
import { FormProps } from '../../types/props'
import { getStyle } from '../../services/HelperService'
import { FormRenderer } from '../FormRenderer/FormRenderer'
import { Dialog, DialogContent, IconButton, useMediaQuery } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useRecipient } from '../../contexts/RecipientContext'

import './Form.scss'

export const Form = (formProps: FormProps) => {
	const {actions: {setProp}, id, parent} = useNode(node => ({
		id: node.id, 
		parent: node.data.parent as string
	}))
	const [step, setStep] = useState(formProps.activeStep)
	const { query: {node} } = useEditor()
	const mobile = useMediaQuery('(max-width:500px)')
  const {recipient}: any = useRecipient()

	let parentNode = node(parent).get()
	let style: CSSProperties = {
		...getStyle(formProps.style, formProps.mobileStyle, parentNode, mobile)
	}

	useEffect(() => {
		if (formProps.activeStep !== 0) {
			setStep(0)
		}
	}, [])

	const onStepUpdated = (step: number) => {
		setStep(step)
		// setProp((props: FormProps) => props.activeStep = step)
	}

	return <div id={id}
		onClick={(event) => event.stopPropagation()}
		style={{boxSizing: 'border-box', minHeight: 'fit-content', ...style}}>
		<FormRenderer form={formProps}
			step={recipient?.redemption || step === -1 ? -1 : 0}
			onStepUpdated={onStepUpdated}/>
		<Dialog open={step > 0}
			onClose={() => onStepUpdated(0)}>
			<DialogContent>
				<FormRenderer form={formProps}
					step={step}
					onStepUpdated={onStepUpdated}/>
			</DialogContent>
			<IconButton aria-label="close"
          onClick={() => onStepUpdated(0)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
		</Dialog>	
	</div>
}

Form.craft = {
  displayName: 'Form'
}
