import React, { useEffect } from 'react'

import { useEditor, useNode } from '@craftjs/core'
import { getStyle } from '../../services/HelperService'
import { TextProps } from '../../types/props'
import { useMediaQuery } from '@mui/material'
import { useRecipient } from '../../contexts/RecipientContext'
import './Text.scss'

export const Text = (textProps: TextProps) => {
  const {actions: {history}, query: {node}} = useEditor()
  const {actions: {setProp}, parent} = useNode((state) => ({
    id: state.id,
    selected: state.events.selected,
    dom: state.dom,
    parent: state.data.parent
  }))
  const mobile = useMediaQuery('(max-width:500px)')
  const {variables}: any = useRecipient()
  const addFontTag = () => {
    let style = document.createElement('style')
    style.innerHTML = `@import url('https://fonts.googleapis.com/css2?family=${textProps.style.fontFamily.replace(/\s/g, '+')}:ital,wght@0,400;0,700;1,400;1,700&display=swap');`
    document.head.appendChild(style)
  }

  let parentNode = node(parent).get()
  let style = {
    maxHeight: '100%',
    position: 'relative',
    ...textProps.style,
    ...getStyle(textProps.style, textProps.mobileStyle, parentNode, mobile),
  }

  useEffect(() => {
    addFontTag()
  }, [])

  useEffect(() => {
    if (variables && textProps.html) {
      let new_html = `${textProps.html}`

      new_html = new_html.replaceAll(/({{)(.*?)(}})/g, (match: string, offset: number) => {
        const key = match.substring(2, match.length - 2)

        return variables[match]
      })

      setProp((props: TextProps) => props.html = new_html)
    }
  }, [variables])

  return <div className="dm-edit-text"
    style={style}>
    {
      textProps.html &&
      <div dangerouslySetInnerHTML={{__html: textProps.html}}>
      </div> 
    }
  </div>
}

Text.craft = {
  displayName: 'Text'
}
