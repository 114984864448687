import React, { useEffect, PropsWithChildren } from 'react'

import { UserComponent, Node } from '@craftjs/core'
import { WrapperProps } from '../../types/props'

export const WebsiteWrapper: UserComponent<PropsWithChildren<WrapperProps>> = (props) => {
	useEffect(() => {
		if (!props.size && props.setSize) {
			let wrapper = document.getElementsByClassName('dm-edit-cardscale')[0].getElementsByTagName('div')[0]
			if (wrapper) {
				let new_size = {
					width: Math.ceil(wrapper.clientWidth),
					height: Math.ceil((wrapper.clientHeight + 50))
				}

				props.setSize(new_size)
			}
		}
	}, [])

	return <div>
		{props.children}
	</div>
}

WebsiteWrapper.craft = {
	displayName: 'Website'
}
