import React, { PropsWithChildren } from 'react'

import { useMediaQuery } from '@mui/material'
import './LandingPage.scss'

export const LandingPage = ({background, children}: PropsWithChildren<{bleed?: boolean, background: string}>) => {
  const mobile = useMediaQuery('(max-width:500px)')

  return <div className={`dm-edit-landing dm-edit-landing--live ${mobile ? 'dm-edit-landing--mobile' : ''}`}
    style={{background}}>
    { children }
  </div>
}

LandingPage.craft = {
  displayName: 'LandingPage'
}
