import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext } from 'react'

const RecipientContext = createContext(null)

const useRecipient = (): {recipient: any, token: string, setRecipient: Dispatch<SetStateAction<any>>, variables: any} => {
	const [recipient, token, setRecipient, variables] = useContext(RecipientContext)

	return {recipient, token, setRecipient, variables}
}

const RecipientProvider = ({value, children}: PropsWithChildren<{value: [ recipient: any, token: string, setRecipient: Dispatch<SetStateAction<any>>, variables: any]}>) => (
	<RecipientContext.Provider value={value}>{children}</RecipientContext.Provider>
)

export { useRecipient, RecipientProvider }