import React, { CSSProperties, PropsWithChildren, useEffect } from 'react'

import { useEditor, useNode } from '@craftjs/core'
import { ColumnProps } from '../../types/props'
import { useMediaQuery } from '@mui/material'
import './Column.scss'

export const Column = (props: PropsWithChildren<ColumnProps>) => {
	const {parent, selected} = useNode(node => ({
		parent: node.data.parent,
		selected: node.events.selected
	}))
	const {actions: {selectNode}, query: {node}} = useEditor()
	const mobile = useMediaQuery('(max-width:500px)')
	let parentNode = node(parent).get()

	let style: CSSProperties = {
		gridTemplateColumns: `repeat(${mobile ? 36 : props.grids}, 1fr)`
	}

	if (parentNode.data.props.style.minHeight) {
		style.minHeight = parentNode.data.props.style.minHeight
	}

	useEffect(() => {
		if (selected) {
			selectNode(parent)
		}
	}, [selected])

	return <div style={style}
		className="dm-edit-section-column">
		{ props.children }
	</div>
}
