import React, { CSSProperties, PropsWithChildren, useEffect, useState } from 'react'

import { Node, useEditor, useNode } from '@craftjs/core'
import { getStyle } from '../../services/HelperService'
import { useMediaQuery } from '@mui/material'
import { IconButton } from '@mui/material'
import { SocialProps } from '../../types/props'
import './Social.scss'

export const Social = (props: SocialProps) => {
  const {connectors: {connect}, parent, id} = useNode(node => ({parent: node.data.parent, id: node.id}))
  const { query: {node}, actions: {history} } = useEditor()
  const mobile = useMediaQuery('(max-width:500px)')

  let parentNode: Node = node(parent).get()
  let style: CSSProperties = {
    ...getStyle(props.style, props.mobileStyle, parentNode, mobile),
  }

  return <div ref={ref => connect(ref)}
    className='dm-edit-social'
    style={{...props.style, ...style}}>
    {
      ['facebook', 'twitter', 'instagram', 'linkedin', 'youtube'].map(item => {
        return props[item as keyof SocialProps] ? <IconButton sx={{
          minWidth: 0,
          padding: 0,
          height: '30px',
          width: '30px'
        }}>
          <svg style={{height: '100%'}}>
						<use href={`assets/icons/social.svg#${item}-mono`} />
					</svg>
        </IconButton> : <></>
      })
    }
  </div>
}

Social.craft = {
  displayName: 'Social'
}