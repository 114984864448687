import React, { useEffect } from 'react'

import { Editor, Frame, useEditor } from '@craftjs/core'
import { WebButton } from './components/WebButton/WebButton'
import { LandingPage } from './components/LandingPage/LandingPage'
import { Section } from './components/Section/Section'
import { Renderer } from './components/Renderer/Renderer'
import { WebsiteWrapper } from './components/WebsiteWrapper/WebsiteWrapper'
import { Video } from './components/Video/Video'
import { Form } from './components/Form/Form'
import { PopupForm } from './components/PopupForm/PopupForm'
import { WebMap } from './components/WebMap/WebMap'
import { Text } from './components/Text/Text'
import { Social } from './components/Social/Social'
import { Column } from './components/Column/Column'
import { WebsiteImage } from './components/WebsiteImage/WebsiteImage'

import './App.scss'

function App() {
  return <Editor resolver={{
      WebButton,
      LandingPage,
      Section,
      Renderer, 
      WebsiteWrapper,
      Video, 
      Form, 
      PopupForm, 
      WebMap, 
      Text, 
      Column, 
      WebsiteImage,
      Social
    }}
    enabled={false}>
    <Renderer />
  </Editor>
}

export default App
