import React, { CSSProperties } from 'react'

import { Node, useEditor, useNode } from '@craftjs/core'
import { styled } from '@mui/system'
import { WebMapProps } from '../../types/props'
import { getStyle } from '../../services/HelperService'
import GoogleMapReact from 'google-map-react'
import { useMediaQuery } from '@mui/material'

export const WebMap = (props: WebMapProps) => {
  const { parent } = useNode(node => ({parent: node.data.parent}))
  const { query: {node} } = useEditor()
	const mobile = useMediaQuery('(max-width:500px)')

  let parentNode: Node = node(parent).get()
  let style: CSSProperties = {
    ...getStyle(props.style, props.mobileStyle, parentNode, mobile)
  }  


  return <div style={{...props.style, ...style}}>
   <GoogleMapReact defaultZoom={14}
    defaultCenter={{lat: 25.7633056, lng: -80.1910755}}
    zoom={props.zoom}>
    <Marker lat={25.7633056} lng={-80.1910755}/>
   </GoogleMapReact>
  </div>
}

const Marker = ({ lat, lng }: {lat: number, lng: number}) => {
  const Wrapper =  styled('div')`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    background-color: #000;
    border: 2px solid #fff;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
    cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
    &:hover {
      z-index: 1;
    }
  `
  return <Wrapper/>
}

WebMap.craft = {
  displayName: 'Map'
}
