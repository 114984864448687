import React, { CSSProperties, useState } from 'react'

import { useEditor, useNode } from '@craftjs/core'
import { Button, Dialog, DialogContent, IconButton, useMediaQuery } from '@mui/material'
import { FormRenderer } from '../FormRenderer/FormRenderer'
import { PopupFormProps } from '../../types/props'
import { getStyle } from '../../services/HelperService'
import CloseIcon from '@mui/icons-material/Close'
import './PopupForm.scss'

export const PopupForm = (popupFormProps: PopupFormProps) => {
	const [errors, setErrors] = useState<{[key: string]: string}>({})
	const {actions: {setProp}, parent, id} = useNode(node => ({
		parent: node.data.parent,
		id: node.id
	}))
	const { actions: {history}, query: {node} } = useEditor()
	const mobile = useMediaQuery('(max-width:500px)')
	const [step, setStep] = useState(popupFormProps.form.activeStep)

	let parentNode = node(parent).get()
	let style: CSSProperties = {
		textTransform: 'none',
		...getStyle(popupFormProps.style, popupFormProps.mobileStyle, parentNode, mobile)
	}

	const toggleDialog = () => {
		history.ignore().setProp(id, (props: PopupFormProps) => props.open = !props.open)
	}

	const onStepUpdated = (step: number) => {
		setStep(step)
		setProp((props: PopupFormProps) => props.form.activeStep = step)
	}

	return <>
		<Button style={style}
			onClick={toggleDialog}>
			{ popupFormProps.label }
		</Button>
		<Dialog open={popupFormProps.open}
			onClose={toggleDialog}
			fullScreen={mobile}>
			<DialogContent>
				<FormRenderer form={popupFormProps.form}
					step={step}
					onStepUpdated={onStepUpdated}/>
				<IconButton aria-label="close"
          onClick={toggleDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
			</DialogContent>
		</Dialog>
	</>
}

PopupForm.craft = {
	displayName: 'Form Popup'
}
