import React, {CSSProperties, useEffect} from 'react'

import { Node, useEditor, useNode } from '@craftjs/core'
import { ImageProps } from '../../types/props'
import { getBorder, getStyle } from '../../services/HelperService'
import { useMediaQuery } from '@mui/material'
import { useRecipient } from '../../contexts/RecipientContext'

import './WebsiteImage.scss'

export const WebsiteImage = (imageProps: ImageProps) => {
  const { parent, actions: {setProp} } = useNode((node) => ({parent: node.data.parent}))
  const { query: {node} } = useEditor()
  const mobile = useMediaQuery('(max-width:500px)')
	const { variables }: any = useRecipient()

  let parentNode: Node = node(parent).get()
  let attributes: any = {}

  useEffect(() => {
		if (imageProps.variable && variables?.[imageProps.variable.var]) {
			// Get value of the used variable here 
			setProp((props: ImageProps) => props.url = variables[imageProps.variable.var])
		}
	}, [variables])

  let style: CSSProperties = {
    ...getStyle(imageProps.style, imageProps.mobileStyle, parentNode, mobile), 
    ...getBorder(imageProps.border),
    overflow: 'hidden',
    transform: `scaleX(${imageProps.scaleX}) scaleY(${imageProps.scaleY})`,
    cursor: attributes.href ? 'pointer' : 'initial'
  }

  if (imageProps.fit === 'full') {
    style.top = `${imageProps.grid_top * (mobile ? 8 : 15)}px`
    style.height = `${imageProps.grid_height * (mobile ? 8 : 15)}px`
  }

  const getImage = () => {
    return <img className={`dm-edit-web-image ${imageProps.fit === 'contain' ? 'dm-edit-web-image--contain':''}`}
      style={{
        width: imageProps.imageWidth,
        height: imageProps.imageHeight}}
      draggable="false"
      src={imageProps.url || imageProps.variable?.preview}/>
  }

  return <div style={style}
    {...attributes}>
    { imageProps.link_enabled && imageProps.link ?
      <a href={imageProps.link} target="_blank">
        { getImage() }
      </a> :
      getImage()
    }
  </div>
}

WebsiteImage.craft = {
  displayName: 'Image'
}
