import React, { CSSProperties } from 'react'

import { Button, useMediaQuery } from '@mui/material'
import { getBorder, getRgbaParts, getStyle } from '../../services/HelperService'
import { useEditor, useNode } from '@craftjs/core'
import { WebButtonProps } from '../../types/props'

export const WebButton = (webButtonProps: WebButtonProps) => {
  const {query: {node}} = useEditor()
  const {parent} = useNode((state) => ({
    parent: state.data.parent as string
  }))
  const mobile = useMediaQuery('(max-width:500px)')

	let parentNode = node(parent).get()
  let border = getBorder(webButtonProps.border)
  let style: CSSProperties = {
    ...border,
		...getStyle(webButtonProps.style, webButtonProps.mobileStyle, parentNode, mobile)
	}
  let rgba = webButtonProps.style.backgroundColor ? getRgbaParts(webButtonProps.style.backgroundColor) : null
  let hover

  if (rgba) {
    hover = `rgba(${rgba[1]},${rgba[2]},${rgba[3]},${parseInt(rgba[4]) - 0.1})`
  }

  return <Button variant="outlined"
    sx={{
      ...style,
      '&:hover': {
        backgroundColor: rgba ? hover : 'inherit',
        ...border
      }
    }}
    target="_blank"
    href={`//${webButtonProps.url}`}>
    <div>{ webButtonProps.label }</div>
  </Button>
}

WebButton.craft = {
  displayName: 'Button'
}
