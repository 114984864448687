import React, { PropsWithChildren } from 'react'

import { SectionProps } from '../../types/props'
import { useMediaQuery } from '@mui/material'
import './Section.scss'

export const Section = (props: PropsWithChildren<SectionProps>) => {
	const mobile = useMediaQuery('(max-width:500px)')

	let style = {
		width: '100%',
		...props.style,
	}

	if (mobile) {
		style = {...style, ...props.mobileStyle}
	}
	
	return <div className={`dm-edit-section dm-edit-section--${ props.columns ? 'columns' : 'grid' }`}
		style={style}>
		<div className="dm-edit-section__content">
			{ props.children }
		</div>
	</div>
}

Section.craft = {
	name: 'Section'
}
