export const us_states = [
  {
    "label": "Alaska",
    "value": "AK"
  },
  {
    "label": "Alabama",
    "value": "AL"
  },
  {
    "label": "Arkansas",
    "value": "AR"
  },
  {
    "label": "American Samoa",
    "value": "ASM"
  },
  {
    "label": "Arizona",
    "value": "AZ"
  },
  {
    "label": "California North",
    "value": "CA"
  },
  {
    "label": "Colorado",
    "value": "CO"
  },
  {
    "label": "Connecticut",
    "value": "CT"
  },
  {
    "label": "District of Columbia",
    "value": "DC"
  },
  {
    "label": "Delaware",
    "value": "DE"
  },
  {
    "label": "Florida",
    "value": "FL"
  },
  {
    "label": "Georgia",
    "value": "GA"
  },
  {
    "label": "Guam",
    "value": "GUM"
  },
  {
    "label": "Hawaii",
    "value": "HI"
  },
  {
    "label": "Iowa",
    "value": "IA"
  },
  {
    "label": "Idaho",
    "value": "ID"
  },
  {
    "label": "Illinois",
    "value": "IL"
  },
  {
    "label": "Indiana",
    "value": "IN"
  },
  {
    "label": "Kansas",
    "value": "KS"
  },
  {
    "label": "Kentucky",
    "value": "KY"
  },
  {
    "label": "Louisiana",
    "value": "LA"
  },
  {
    "label": "Massachusetts",
    "value": "MA"
  },
  {
    "label": "Maryland",
    "value": "MD"
  },
  {
    "label": "Maine",
    "value": "ME"
  },
  {
    "label": "Michigan",
    "value": "MI"
  },
  {
    "label": "Minnesota",
    "value": "MN"
  },
  {
    "label": "North Mariana Islands",
    "value": "MNP"
  },
  {
    "label": "Missouri",
    "value": "MO"
  },
  {
    "label": "Mississippi",
    "value": "MS"
  },
  {
    "label": "Montana",
    "value": "MT"
  },
  {
    "label": "North Carolina",
    "value": "NC"
  },
  {
    "label": "North Dakota",
    "value": "ND"
  },
  {
    "label": "Nebraska",
    "value": "NE"
  },
  {
    "label": "New Hampshire",
    "value": "NH"
  },
  {
    "label": "New Jersey",
    "value": "NJ"
  },
  {
    "label": "New Mexico",
    "value": "NM"
  },
  {
    "label": "Nevada",
    "value": "NV"
  },
  {
    "label": "New York",
    "value": "NY"
  },
  {
    "label": "Ohio",
    "value": "OH"
  },
  {
    "label": "Oklahoma",
    "value": "OK"
  },
  {
    "label": "Oregon",
    "value": "OR"
  },
  {
    "label": "Pennsylvania",
    "value": "PA"
  },
  {
    "label": "Puerto Rico",
    "value": "PRI"
  },
  {
    "label": "Rhode Island",
    "value": "RI"
  },
  {
    "label": "South Carolina",
    "value": "SC"
  },
  {
    "label": "South Dakota",
    "value": "SD"
  },
  {
    "label": "Tennessee",
    "value": "TN"
  },
  {
    "label": "Texas",
    "value": "TX"
  },
  {
    "label": "Utah",
    "value": "UT"
  },
  {
    "label": "Virginia",
    "value": "VA"
  },
  {
    "label": "U.S. Virgin Islands",
    "value": "VIR"
  },
  {
    "label": "Vermont",
    "value": "VT"
  },
  {
    "label": "Washington",
    "value": "WA"
  },
  {
    "label": "Wisconsin",
    "value": "WI"
  },
  {
    "label": "West Virginia",
    "value": "WV"
  },
  {
    "label": "Wyoming",
    "value": "WY"
  },
  {
    "label": "Midway Islands",
    "value": "XMI"
  }
]
